<template>
  <div class="new-page">
    <h1>Welcome to the New Page</h1>
    <p>This is a new page. You can add your content here.</p>
  </div>
</template>

<script>
export default {
  name: 'MaliciousDataset',
  data() {
    return {
      // Your data properties go here
    };
  },
  methods: {
    // Your methods go here
  },
  computed: {
    // Your computed properties go here
  },
  mounted() {
    // Any code you want to run when the component is mounted
  }
}
</script>

<style scoped>
.new-page {
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}
</style>
