<template>
  <el-container>
    <el-header
        style="box-shadow: 0px 15px 10px -15px #72767b; height: 65px; position: fixed; width: 100%; z-index: 9999; background-color: #ffffff; padding:0 15%">
      <el-row>
        <el-col :span="6">
          <img src="../assets/img/kellect.png" style="width: 160px; margin-top: 12px; margin-left: 5px;cursor: pointer;" @click="goToHome"/>
        </el-col>
      </el-row>
    </el-header>
    <el-main style="margin-top: 3%;margin-left: 15%;margin-right: 15%">
      <!-- Main Title -->
      <el-row class="title-row">
        <el-col :span="24">
          <div style="width: 100%;text-align: center"><h1>Kellect4APT Dataset</h1></div>
          <div style="text-align: center;font-size: 25px;font-weight: bolder; color: red;cursor: pointer;" @click="downloadDataset">
            <img src="../assets/img/download.png" alt="" width="25" height="25">DOWNLOAD (Google Drive)
          </div>
          <div style="text-align: center;font-size: 25px;font-weight: bolder; color: red;cursor: pointer;" @click="downloadDatasetChinese">
            <img src="../assets/img/download.png" alt="" width="25" height="25">DOWNLOAD (中国移动网盘，password:c8gm)
          </div>
        </el-col>
      </el-row>

      <!-- Introduction -->
      <el-row class="intro-row">
        <el-col :span="24">
          <p>
            <span class="justify">
              Detecting sophisticated cyber attacks increasingly relies on knowledge bases constructed from threat intelligence. We can achieve APT attack detection, traceability, and early warning strategies by utilizing provenance graphs exported from kernel logs. In cyber security, the ATT&CK[1] framework is a widely adopted knowledge base and framework that describes attack techniques from the attackers' perspective. It categorizes tactics used by cyber threat groups and provides a powerful reference for knowledge-based detection methods. The framework defines contents based on operating systems, including Windows, Linux, etc. Red Canary[2] offers scripts that implement ATT&CK behaviors, allowing security teams to rapidly construct testing environments and behavior scripts in a portable, repeatable fashion.
            </span>
          </p>
        </el-col>
      </el-row>

      <el-row class="intro-row">
        <el-col :span="24">
          <p>
            <span class="justify">
              We propose the Kellect4APT dataset, based on kernel behavior loss, to provide behavior patterns for the ATT&CK model.
            </span>
          </p>
        </el-col>
      </el-row>

      <el-row class="intro-row">
        <el-col :span="24" style="text-align: center">
          <img src="../assets/img/aptdataset.png" style="width:80%; margin-bottom: 1%">
        </el-col>
      </el-row>

      <!-- Subtitle: Introduction to Kellect4APT -->
      <el-row class="subtitle-row">
        <el-col :span="24">
          <div style="width: 100%;"><h2>Introduction to Kellect4APT:</h2></div>
        </el-col>
      </el-row>

      <!-- Description about Kellect4APT -->
      <el-row class="desc-row">
        <el-col :span="24">
          <p>
            <span class="justify">
              Kellect4APT uses the open-source collector Kellect[3] for data collection. Kellect is a real-time, non-invasive, efficient Windows kernel log collector that can provide rich kernel behavior data as the basis for user and application behavior. For detailed data information, please refer to Windows_43Techniques.csv. The dataset involves 9 tactics with 43 sub-techniques and 304 sample files, totaling 18.5GB. The MD5 value is dd662016d3d21242055bbbc201e4d6e4.
            </span>
          </p>
        </el-col>
        <el-col :span="24">
          <el-table :data="tableData1" border style="width: 100%">
            <el-table-column
                prop="key"
                label="Attribute"
                width="380">
            </el-table-column>
            <el-table-column
                prop="value"
                label="Value">
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <!-- Subtitle: Data collection environment -->
      <el-row class="subtitle-row">
        <el-col :span="24">
          <h3>1.Data collection environment</h3>
        </el-col>
      </el-row>

      <!-- Description about Data collection environment -->
      <el-row class="desc-row">
        <el-col :span="24">
          <p>
            <span class="justify">
              Experimental environment: Based on Windows 10, the CPU of the host is i5-10400F, the GPU is 1050ti, the memory is 16GB. Data was collected with specified noise and scripts running. The specific noise environment is as follows:
            </span>
          </p>
        </el-col>
        <el-col :span="24">
          <el-table :data="tableData2" border style="width: 100%">
            <el-table-column
                prop="key"
                label="Attribute"
                width="380">
            </el-table-column>
            <el-table-column
                prop="value"
                label="Value">
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <!-- Subtitle: Sample storage -->
      <el-row class="subtitle-row">
        <el-col :span="24">
          <h3>2.Sample storage</h3>
        </el-col>
      </el-row>

      <!-- Description about Sample storage -->
      <el-row class="desc-row">
        <el-col :span="24">
          <p>
            <span class="justify">
              The files are stored in JSON format, with each JSON representing an event behavior. A single sample file represents the collection of event behaviors from running a script.
            </span>
          </p>
        </el-col>
      </el-row>


      <!-- Subtitle: Collection content -->
      <el-row class="subtitle-row">
        <el-col :span="24">
          <h3>3.Collection content</h3>
        </el-col>
      </el-row>

      <!-- Description about Collection content -->
      <el-row class="desc-row">
        <el-col :span="24">
          <p>
            <span class="justify">
              Referring to the events provided by Windows[4], we collect and analyze a total of the following seven events, which are included as follows:
            </span>
          </p>
        </el-col>
        <el-col :span="24">
          <el-table :data="tableData3" border style="width: 100%">
            <el-table-column
                prop="key"
                label="Event Type"
                width="280">
            </el-table-column>
            <el-table-column
                prop="value"
                label="Event Name">
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24">
          <p>
            <span class="justify">
              In this case, each event has two parts of attributes: public and private attributes. Each public is described as follows:
            </span>
          </p>
        </el-col>
      </el-row>


      <!-- Common Properties -->
      <el-row class="subtitle-row">
        <el-col :span="24">
          <h4>Common properties:</h4>
        </el-col>
      </el-row>

      <!-- Common Properties -->
      <el-row class="desc-row">
        <el-col :span="24">
          <el-table :data="tableData4" border style="width: 100%">
            <el-table-column
                prop="key"
                label="Property"
                width="280">
            </el-table-column>
            <el-table-column
                prop="value"
                label="Description">
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <!-- Common Properties -->
      <el-row class="subtitle-row">
        <el-col :span="24">
          <h4>Private properties:</h4>
        </el-col>
      </el-row>

      <!-- Common Properties -->
      <el-row class="desc-row">
        <el-col :span="24">
          <p>
            <span class="justify">
              This type properties is various depends on the BaseEvent types. For example:
            </span>
          </p>
        </el-col>
        <el-col :span="24" >
          <p>
            <span class="justify">
              (1)file_create event
            </span>
          </p>
          <el-row class="desc-row2">
            <el-col :span="24" class="center-table">
              <el-table :data="tableData5" border style="width: 100%">
                <el-table-column
                    prop="key"
                    label="Property"
                    width="280">
                </el-table-column>
                <el-table-column
                    prop="value"
                    label="Description">
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <p>
            <span class="justify">
              (2)FileIo_Name event
            </span>
          </p>
          <el-table :data="tableData6" border style="width: 100%">
            <el-table-column
                prop="key"
                label="Property"
                width="280">
            </el-table-column>
            <el-table-column
                prop="value"
                label="Description">
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24">
          <p>
            <span class="justify">
              for other event attribute descriptions, please refer to Microsoft Documentation[5].
            </span>
          </p>
        </el-col>
      </el-row>


      <!-- Subtitle: Applications -->
      <el-row class="subtitle-row">
        <el-col :span="24">
          <h3>4.Applications</h3>
        </el-col>
      </el-row>

      <!-- Description about Applications -->
      <el-row class="desc-row">
        <el-col :span="24">
          <p>
            <span class="justify">
              The dataset can be used to perform causal origin analysis of behaviors. As an example, T1003.006-1 is analyzed by generating the traceability graph T1003.006-1.pdf, and this technique uses ProcDump to dump the LSASS.exe memory. The specific steps of its traceability are as follows:
              <br><br>1.Open a command prompt (Administrator).
              <br>2.Navigate to the ProcDump executable directory, usually: cd C:\Sysinternals.
              <br>3.Execute the command: "procdump -ma lsass.exe lsass.dmp". This command will dump the memory of the
              <br>&nbsp;&nbsp;&nbsp;LSASS.exe process to the lsass.dmp file, the "-ma" parameter means dump all memory pages.
              <br>4.Wait for the completion of the command execution, lsass.dmp file that is the LSASS process memory dump.
            </span>
          </p>
        </el-col>
      </el-row>

      <!-- Subtitle: Introduction to Kellect4APT -->
      <el-row class="subtitle-row">
        <el-col :span="24">
          <div style="width: 100%;"><h2>Quote</h2></div>
        </el-col>
      </el-row>

      <!-- Description about Kellect4APT -->
      <el-row class="desc-row">
        <el-col :span="24">
          <ol>
            <li><span class="justify">ATT&CK Matrix for Enterprise. <a href="https://attack.mitre.org/">https://attack.mitre.org/</a></span></li>
            <li><span class="justify">redcanaryco/atomic-red-team. <a href="https://github.com/redcanaryco/atomic-red-team">https://github.com/redcanaryco/atomic-red-team</a></span></li>
            <li><span class="justify">Kellect. <a href="https://www.kellect.org/">https://www.kellect.org/</a></span></li>
            <li><span class="justify">EventTrace class. <a href="https://learn.microsoft.com/en-us/windows/win32/etw/eventtrace">https://learn.microsoft.com/en-us/windows/win32/etw/eventtrace</a></span></li>
            <li><span class="justify">Microsoft. Overview of ETW. <a href="https://learn.microsoft.com/en-us/windows/win32/etw/msnt-systemtrace">https://learn.microsoft.com/en-us/windows/win32/ etw/msnt-systemtrace</a></span></li>
          </ol>
        </el-col>
      </el-row>

      <!-- Description about Kellect4APT -->
      <el-row class="desc-row">
        <el-col :span="24">
          <div style="width: 100%;text-align: center"><el-button @click="navigateToNewPage('main')">返回主页</el-button></div>
        </el-col>
      </el-row>


    </el-main>

    <el-footer style="margin-top: 3%; background-color: #333333;">
      <div class="center" style="text-align: center; width: 100%; vertical-align: top">
        <svg t="1673338716645" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="6821" width="20" height="20">
          <path
              d="M810.666667 170.666667H213.333333a128 128 0 0 0-128 128v426.666666a128 128 0 0 0 128 128h597.333334a128 128 0 0 0 128-128V298.666667a128 128 0 0 0-128-128z m0 85.333333l-277.333334 190.72a42.666667 42.666667 0 0 1-42.666666 0L213.333333 256z"
              p-id="6822" fill="#EB6A3F"></path>
        </svg>
        <span style="font-size: 18px; color: #f0f0f0; padding-left: 7px">Contact us: songqijie@zjut.edu.cn</span>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: 'Kellect4APTDataset',
  data() {
    return {
      tableData1: [
        { key: 'Number of steps contained (steps)', value: '9' },
        { key: 'Number of sub-techniques (items)', value: '43' },
        { key: 'Number of attack samples (items)', value: '304' },
        { key: 'Size of collected data (GB)', value: '18.5' },
        { key: 'MD5', value: 'dd662016d3d21242055bbbc201e4d6e4' },
      ],
      tableData2: [
        { key: 'Noise 1', value: 'Video live streaming and common office software' },
        { key: 'Noise 2', value: 'Gaming and file downloading' }
      ],
      tableData3: [
        { key: 'Process', value: 'ProcessStart, ProcessEnd, ProcessDCStart, ProcessDCEnd' },
        { key: 'Thread', value: 'ThreadStart, ThreadEnd, ThreadDCStart, ThreadDCEnd' },
        { key: 'Image', value: 'ImageDCStart, ImageLoad' },
        { key: 'TCP/IP', value: 'TcpIpSendIPV4, TcpIpSendIPV6, TcpIpRetransmitIPV4, TcpIpRetransmitIPV6, TcpIpRecvIPV4, TcpIpRecvIPV6, TcpIpConnectIPV4, TcpIpReconnectIPV6, TcpIpConnectIPV6, TcpIpReconnectIPV4, TcpIpAcceptIPV4, TcpIpAcceptIPV6, TcpIpDisconnectIPV4, TcpIpDisconnectIPV6' },
        { key: 'FileIO', value: 'FileIOWrite, FileIORead, FileIOFileCreate, FileIORename, FileIOCreate, FileIOCleanup, FileIOFlush, FileIOClose, FileIODelete, FileIOFileDelete' },
        { key: 'DiskIO', value: 'DiskIOWrite, DiskIORead' },
        { key: 'Registry', value: 'RegistryCreate, RegistrySetValue, RegistryDeleteValue, RegistryOpen, RegistryDelete, RegistryFlush, RegistryClose, , RegistrySetInformation, RegistryQuery, RegistryQueryValue' }
      ],
      tableData4: [
        { key: 'Event', value: 'corresponding event name' },
        { key: 'TID', value: 'ID of the thread that generated the event' },
        { key: 'PID', value: 'ID of the process that generated the event' },
        { key: 'PName', value: 'name of the process that generated the event' },
        { key: 'PPID', value: 'parent ID of the process that generated the event' },
        { key: 'PPName', value: 'parent name of the process that generated the event' },
        { key: 'TimeStamp', value: 'time of the event occurred' },
        { key: 'Host-UUID', value: 'the host ID that generated the event, distinguish the specific log source in the joint analysis of multi-host logs' },
        { key: 'Args', value: 'private property pairs for specific event types' },
      ],
      tableData5: [
        { key: 'IrpPtr', value: 'IO request packet' },
        { key: 'TTID', value: 'Thread identifier of the thread that is creating the file' },
        { key: 'FileObject', value: 'Identifier that can be used for correlating operations to the same opened file object instance between file create and close events' },
        { key: 'CreateOptions', value: 'Values passed in the CreateOptions and CreateDispositions parameters to the NtCreateFile function' },
        { key: 'FileAttributes', value: 'Value passed in the FileAttributes parameter to the NtCreateFile function' },
        { key: 'ShareAccess', value: 'Value passed in the ShareAccess parameter to the NtCreateFile function' },
        { key: 'OpenPath', value: 'Path to the file' }
      ],
      tableData6: [
        { key: 'FileObject', value: 'Match the value of this pointer to the FileObject pointer value in a DiskIo_TypeGroup1 event to determine the type of I/O operation' },
        { key: 'FileName', value: 'Full path to the file, not including the drive letter' }
      ],
    };
  },
  methods: {
    navigateToNewPage(newpage) {
      switch (newpage){
        case 'main': this.$router.go(-1); break;
      }
    },
    downloadDataset(){
      let path="https://drive.google.com/drive/folders/1hfqG-u3ChAQD-2SaIVlCKCtCNm7bcNjO?usp=sharing"
      window.open(path)
    },
    downloadDatasetChinese(){
      let path="https://caiyun.139.com/m/i?095Cub226LmPf"
      window.open(path)
    },
    goToHome(){
      this.$router.push({name:'HomePage'})
    }
  },
  computed: {
    // Your computed properties go here
  },
  mounted() {
    // Any code you want to run when the component is mounted
    document.documentElement.scrollTop = 0
  }
}
</script>

<style scoped>
.new-page {
  padding: 20px;
  text-align: center;
}

.desc-row2 {
  display: flex;
  justify-content: center;
  align-items: center;  /* 如果你想在垂直方向上也居中的话 */
  /* min-height: 100vh; */  /* 如果你想让它充满视口的高度并在其中居中的话 */
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.justify {
  font-size: 18px;
  text-align: justify;
  display: inline-block
}
</style>
