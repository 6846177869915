import Vue from 'vue';
import Router from 'vue-router';
import Kellect4APTDataset from '@/views/Kellect4APTDataset.vue';
import MaliciousDataset from '@/views/MaliciousDataset.vue';
import HomePage from "@/views/HomePage.vue";


Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    {
      path: '/kellect-4-aptdataset',
      name: 'Kellect4APTDataset',
      component: Kellect4APTDataset
    },
    {
      path: '/malicious-dataset',
      name: 'MaliciousDataset',
      component: MaliciousDataset
    }
  ]
});
